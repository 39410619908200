// =============================
// Imports
// =============================

import styled from 'styled-components';
import FinalLink from '../../../other/link';

// =============================
// Styles
// =============================

export const ForgotPasswordLink = styled(FinalLink)`
  font-size: 1.3rem;
  margin-bottom: 3.6rem;
`;

export const RegisterLink = styled.div`
  font-size: 1.3rem;
`;
