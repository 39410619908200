// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Typo } from '@mewo/components';

import MewoInput from '../../../presentationals/mewoInput';
import Input from '../../../presentationals/inputs/input';
import Link from '../../../other/link';
import NoAuthLanguageSwitcher from '../../../other/noAuthLanguageSwitcher';

import validator from '../../../../validators/auth/login';

import { InputWrapper } from '../../global.styles';
import { Background, Content, ContentWrapper, FormSubmit, Logo, Notification, Wrapper } from '../auth.styles';
import { ForgotPasswordLink, RegisterLink } from './styles';

// =============================
// Page
// =============================

const Login = ({ getUserSessionToken, t }) => (
  <Wrapper>
    <Content>
      <ContentWrapper>
        <Logo />

        <Form onSubmit={getUserSessionToken}>
          {({ submitError, handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              {submitError && <Notification type="error">{submitError}</Notification>}
              <InputWrapper>
                <Field
                  name="email"
                  component={MewoInput}
                  use={Input}
                  type="text"
                  label={t('common:form.email')}
                  validate={validator.email}
                />
                <Field
                  name="password"
                  component={MewoInput}
                  use={Input}
                  type="password"
                  label={t('common:form.password')}
                  validate={validator.password}
                />
              </InputWrapper>

              <FormSubmit type="submit" disabled={submitting} fullWidth>
                {t('pages:login.login')}
              </FormSubmit>
            </form>
          )}
        </Form>

        <ForgotPasswordLink href="/recover">{t('pages:login.forgot_password')}</ForgotPasswordLink>
        <RegisterLink size={1.3}>
          {t('pages:login.new_here')}
          &nbsp;
          <Link inline href="/register">
            <Typo color="primary">{t('pages:login.create_account')}</Typo>
          </Link>
        </RegisterLink>
      </ContentWrapper>

      <NoAuthLanguageSwitcher />
    </Content>
    <Background />
  </Wrapper>
);

Login.propTypes = {
  getUserSessionToken: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default Login;
