// =============================
// Imports
// =============================

// External Dependencies
import { connect } from 'react-redux';

// Actions
import { getUserSessionToken } from '../../../../store/actions/AuthActions';

// Components
import LoginPage from '../../../layouts/auth/login';

// =============================
// Component
// =============================

export default connect(null, { getUserSessionToken })(LoginPage);
