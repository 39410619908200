// =============================
// Imports
// =============================

import PropTypes from 'prop-types';
import Head from 'next/head';

import { withTranslation } from '../config/i18n';

import PublicRoute from '../components/containers/publicRoute';
import Login from '../components/containers/auth/login';

import { InheritSize } from '../components/layouts/global.styles';

import { getWebsiteTitle } from '../helpers/misc';

// =============================
// Page
// =============================

const LoginPage = ({ t }) => (
  <PublicRoute>
    <InheritSize>
      <Head>
        <title>{getWebsiteTitle(t('pages:login.meta_title'))}</title>
      </Head>
      <Login t={t} />
    </InheritSize>
  </PublicRoute>
);

LoginPage.getInitialProps = async () => ({
  namespacesRequired: ['common', 'pages', 'components', 'errors'],
});

LoginPage.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation(['common', 'pages'])(LoginPage);
